import { CORES_AGENDA, COR_AGENDA_DESABILITADO, COR_AGENDA_SELECIONADO } from "./Constants";
import { useMoment } from '../hooks';
const moment = useMoment();

export const mudarNomeTab = (nome) => {
    document.title = nome;
}

export const pedirPermissaoNotificacao = (exibirNotificacao = () => { }) => {
    if (!("Notification" in window)) return;

    if (Notification.permission === 'granted') {
        // Permissão já concedida
        exibirNotificacao();
    } else if (Notification.permission !== 'denied') {
        // Pedir permissão ao usuário
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                exibirNotificacao();
            }
        });
    }
}

export const resetTab = () => {
    mudarNomeTab(global.gConfig.titulo_aplicacao);
}

export const gerarCalendariosScheduleX = () => {
    const cal = {}

    for (let i = 1; i <= CORES_AGENDA.length; i++) {
        cal[`cal${i}`] = {
            colorName: `cal${i}`,
            lightColors: {
                main: CORES_AGENDA[i - 1].barra,
                container: CORES_AGENDA[i - 1].fundo,
                onContainer: '#000', //texto
            },
        }
    }
    cal.disabled = {
        colorName: 'disabled',
        lightColors: {
            main: COR_AGENDA_DESABILITADO.barra,
            container: COR_AGENDA_DESABILITADO.fundo,
            onContainer: '#000',
        },
    }
    cal.selected = {
        colorName: 'selected',
        lightColors: {
            main: COR_AGENDA_SELECIONADO.barra,
            container: COR_AGENDA_SELECIONADO.fundo,
            onContainer: '#000',
        },
    }
    return cal;
}

export const NAO_SE_REPETE = 1;
export const TODOS_OS_DIAS = 2;
export const SEMANAL = 3;
export const MENSAL = 4;
export const SEGUNDA_SEXTA = 5;

export const gerarDatasRecorrencias = (dataEventoRaiz, dataFinal, recorrencia) => {
    let datas = [];
    let dataAtual = dataEventoRaiz.clone();

    if (!dataFinal) {
        return [dataAtual];
    }

    // Converte dataFinal para moment.js para comparações
    const dataFim = moment(dataFinal);

    while (dataAtual.isSameOrBefore(dataFim, 'day')) {
        datas.push(dataAtual.clone()); // Adiciona ao array

        switch (recorrencia.id) {
            case TODOS_OS_DIAS: // Todos os dias
                dataAtual.add(1, 'days');
                break;
            case SEMANAL: // Semanal (mesmo dia da semana)
                dataAtual.add(7, 'days');
                break;
            case MENSAL: { // Mensal
                const semanaDoMes = Math.ceil(dataAtual.date() / 7);
                const diaSemana = dataAtual.day(); // 0 = Domingo, 6 = Sábado

                dataAtual.add(1, 'months'); // Avança para o próximo mês

                if (recorrencia.descricao.includes('último')) {
                    // Encontrar o último [dia da semana] do mês seguinte
                    dataAtual.endOf('month');
                    while (dataAtual.day() !== diaSemana) {
                        dataAtual.subtract(1, 'days');
                    }
                } else {
                    // Encontrar a mesma semana e dia da semana do mês seguinte
                    dataAtual.startOf('month');
                    let count = 0;
                    while (count < semanaDoMes) {
                        if (dataAtual.day() === diaSemana) count++;
                        if (count < semanaDoMes) dataAtual.add(1, 'days');
                    }
                }
                break;
            }
            case SEGUNDA_SEXTA: // Dias úteis (segunda a sexta-feira)
                do {
                    dataAtual.add(1, 'days');
                } while (dataAtual.day() === 0 || dataAtual.day() === 6); // Pula sábado/domingo
                break;

            case NAO_SE_REPETE:
            default:
                return [dataAtual]; // "Não se repete" retorna apenas a data original
        }
    }

    return datas;
};