import React, { useContext, useRef } from "react";
import { SttBox, SttTranslateHook } from '@stt-componentes/core';
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import socket from "../../signals/socket";
import { EVENTOS_SOCKET, } from "../../common/Constants";
import moment from 'moment';
import { batch } from "@preact/signals-react";
import {
    atendimentoEncerrado,
    emChamadaVideo,
    idEvento,
    idSalaAtendimento,
    quantidadeMembrosSala,
    registroAberto
} from "../../signals/eventos";
import ExibicaoMensagemContainer from "./conteudo-mensagem";
import { mudarNomeTab, pedirPermissaoNotificacao, resetTab } from "../../common/Functions";
import usuario from "../../signals/usuario";

const MensagensContainer = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const mensagens = useSignal([]);
    const divRef = useRef();

    const exibirNotificacao = (mensagem) => {
        if (document.hasFocus()) return;

        const options = {
            body: mensagem.anexo ? strings.notificacaoNovaMensagemAnexo : mensagem.mensagem,
            icon: global.gConfig.url_icone_notificacao,
            vibrate: [200, 100, 200],
            tag: 'notificacao-evento',
            renotify: true
        };

        const notification = new Notification(mensagem.responsavel.nome, options);
        mudarNomeTab(strings.notificacaoTabNovaMensagem);

        notification.onclick = () => {
            window?.focus();
            resetTab();
        };

        window.addEventListener('focus', resetTab);
    }

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.MENSAGEM_ENVIADA, (dados) => {
                // Recebimento de uma nova mensagem
                const mensagemRecebida = JSON.parse(dados);
                mensagemRecebida.dataHora = new Date(mensagemRecebida.dataHora);

                mensagens.value = [...mensagens.value, mensagemRecebida];
                setTimeout(() => {
                    divRef.current && divRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start'
                    })

                }, 100);

                if (mensagemRecebida.responsavel.id !== usuario.value.idFuncionario) {
                    pedirPermissaoNotificacao(() => exibirNotificacao(mensagemRecebida));
                }
            });

            socket.value.on(EVENTOS_SOCKET.MENSAGEM_EXCLUIDA, (dados) => {
                dados = JSON.parse(dados);
                const index = mensagens.value.findIndex(m => m._id === dados.idMensagemExcluida);

                mensagens.value[index].excluida = true;
                mensagens.value = [...mensagens.value];
            });

            socket.value.on(EVENTOS_SOCKET.MENSAGEM_ALTERADA, (dados) => {
                dados = JSON.parse(dados);
                const index = mensagens.value.findIndex(m => m._id === dados.idMensagemAlterada);

                if (!mensagens.value[index].mensagensAnteriores) {
                    mensagens.value[index].mensagensAnteriores = [];
                }
                mensagens.value[index].mensagensAnteriores.push(mensagens.value[index].mensagem);
                mensagens.value[index].mensagem = dados.mensagemNova;

                mensagens.value = [...mensagens.value];
            });

            socket.value.on(EVENTOS_SOCKET.MENSAGENS_SALA, (dados) => {
                // Recebimento de todas as mensagens enviadas no chat (usado para conexões posteriores e reconexões)
                dados = JSON.parse(dados);
                dados.mensagens.forEach(m => {
                    if (!(m.dataHora instanceof Date)) {
                        m.dataHora = new Date(m.dataHora);
                    }
                });

                const dataInicio = moment(dados.dataHoraInicio).format('HH:mm - DD/MM/YYYY');
                dados.mensagens.unshift({
                    notificacao: true,
                    mensagem: `Evento iniciado às ${dataInicio}`,
                    atendimento: true
                });

                if (dados.dataHoraTermino) {
                    const dataTermino = moment(dados.dataHoraTermino).format('HH:mm - DD/MM/YYYY');
                    dados.mensagens.push({
                        notificacao: true,
                        mensagem: `Evento finalizado às ${dataTermino}`,
                        atendimento: true
                    });
                }

                mensagens.value = [...dados.mensagens];

                setTimeout(() => {
                    divRef.current && divRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start'
                    })
                }, 100)
            });

            socket.value.on(EVENTOS_SOCKET.DADOS_SALA, (dados) => {
                dados = JSON.parse(dados);

                batch(() => {
                    quantidadeMembrosSala.value = parseInt(dados.quantidadeMembroSala);
                    idSalaAtendimento.value = dados.idSala;
                    idEvento.value = dados.idEvento;
                    emChamadaVideo.value = dados.videoAtivo;
                });
            });

            socket.value.on(EVENTOS_SOCKET.ATENDIMENTO_ENCERRADO, (dados) => {
                dados = JSON.parse(dados)
                const dataAtendimento = moment(dados.dataHoraTermino).format('HH:mm - DD/MM/YYYY');
                batch(() => {
                    emChamadaVideo.value = false;
                    atendimentoEncerrado.value = true;
                    registroAberto.value = false;

                    const novasMensagens = [];
                    novasMensagens.push({
                        notificacao: true,
                        mensagem: `Evento finalizado às ${dataAtendimento}`,
                        atendimento: true
                    });

                    mensagens.value = [...mensagens.value, ...novasMensagens];
                    setTimeout(() => {
                        divRef.current && divRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                            inline: 'start'
                        })
                    }, 100);
                });
            });
        }
    })

    return (
        <SttBox
            sx={{
                height: "99.7%",
                width: "99.7%",
                position: "absolute",
                top: 0,
                display: "flex",
                flexDirection: "column",
                paddingTop: '8px',
                paddingRight: '8px',
                overflow: "auto",
                backgroundColor: '#EFEFEF'
            }}
        >
            <div ref={divRef}>
                <ExibicaoMensagemContainer mensagens={mensagens.value} />
            </div>
        </SttBox>
    );
};

export default MensagensContainer;